import { ErrorCodes } from "atfcore-commonclasses";

/**
 * Configurazione del Router
 */

angular.module("app")
    .run(
        ["$rootScope", "$state", "$stateParams",
            ($rootScope: any, $state: any, $stateParams: any) => {
                $rootScope.$state = $state;
                $rootScope.$stateParams = $stateParams;
            }
        ]
    )
    .config(
        ["$stateProvider", "$urlRouterProvider",
            ($stateProvider: any, $urlRouterProvider: any) => {
                $urlRouterProvider
                    // Se nessuna pagina particolare viene richiesta, allora si va all'autenticazione
                    .otherwise("/app/login");

                $stateProvider
                    .state("app", {
                        abstract: true,
                        url: "/app",
                        templateUrl: "app/shared/global/commonServices.html",
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/login/UserService.js',
                                        'js/shared/notification/NotificationService.js',
                                        'js/shared/kalturaPlayer/kalturaPlayer.js'
                                    ]);
                                }]
                        }
                    })

                    .state("app.access", {
                        abstract: true,
                        url: "/access",
                        template: "<div ui-view class=\"fade-in-right-big smooth\"></div>"
                    })

                    // State posto fra l'autenticazione e la prima pagina dell'applicativo
                    .state('app.intermediate', {
                        url: '/intermediate',
                        templateUrl: 'app/routes/structure/intermediate/intermediate.html',
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(['js/routes/structure/intermediate/IntermediateController.js']);
                                }]
                        }
                    })

                    // Pagina di errore, browser o device non supportati
                    .state("app.compatibilityError", {
                        url: "/compatibilityError",
                        templateUrl: "app/routes/compatibilityError/compatibilityError.html"
                    })

                    // Pagina di Login (fa il login solo se il token esiste già)
                    .state("app.login", {
                        url: "/login",
                        templateUrl: "app/routes/login/login.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/LoginController.js"]);
                                }]
                        }
                    })

                    // Pagina di Login (per amministratori che non fanno login con SSO)
                    .state("app.adminLogin", {
                        url: "/adminLogin",
                        templateUrl: "app/routes/login/adminLogin.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/LoginController.js"]);
                                }]
                        }
                    })

                    // Pagina pubblica che manda, se i dati richiesti lo consentono, manda all'autenticazione (login via SSO che poi manda a login)
                    .state("app.csessologin", {
                        url: "/csessologin",
                        templateUrl: "app/routes/csessologin/csessologin.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/csessologin/CsessologinService.js",
                                        "js/routes/csessologin/CsessologinController.js"]);
                                }]
                        }
                    })

                    // Pagina di Logout
                    .state("app.logout", {
                        url: "/logout",
                        templateUrl: "app/routes/login/login.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        "js/routes/login/LoginController.js"]);
                                }]
                        }
                    })

                    // State astratto che conterrà l'applicativo, l'header e la sidebar
                    .state('app.bgacademyApp', {
                        abstract: true,
                        url: '/bgacademy',
                        templateUrl: 'app/routes/structure/bgacademy/bgacademy.html',
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/structure/bgacademy/BgacademyController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        }
                    })

                    // Definizione della pagina di home
                    .state('app.bgacademyApp.home', {
                        url: '/home',
                        views: {
                            "content": { templateUrl: 'app/routes/home/home.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerCard/itemContainerCard.js',
                                        'js/routes/home/HomeController.js',
                                        'js/routes/home/HomeService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto non legato a nulla
                    .state('app.bgacademyApp.itemDetail', {
                        url: '/itemDetail/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto di un Learning Plan lato utente
                    .state('app.bgacademyApp.learningPlanItemDetailUser', {
                        url: '/learningPlanItemDetailUser/:learningPlanId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto di un Learning Plan lato admin
                    .state('app.bgacademyApp.learningPlanItemDetailAdmin', {
                        url: '/learningPlanItemDetailAdmin/:learningPlanId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto della Playlist lato admin
                    .state('app.bgacademyApp.playlistItemDetailAdmin', {
                        url: '/playlistItemDetailAdmin/:playlistId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto della Playlist lato utente
                    .state('app.bgacademyApp.playlistItemDetailUser', {
                        url: '/playlistItemDetailUser/:playlistId/:itemId',
                        params: { backAction: '', backActionStateParams: null },
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di una Plyalist lato utente
                    .state('app.bgacademyApp.playlistDetailUser', {
                        url: '/playlistDetailUser/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di una Plyalist lato admin
                    .state('app.bgacademyApp.playlistDetailAdmin', {
                        url: '/playlistDetailAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aggiunto ad un Learning Plan
                    .state('app.bgacademyApp.itemDetailLp', {
                        url: '/itemDetailLp/:lpId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto aggiunto ad una sezione
                    .state('app.bgacademyApp.itemDetailSec', {
                        url: '/itemDetailSec/:projectId/:sectionId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })


                    // Definizione della pagina con il dettaglio di un progetto
                    .state('app.bgacademyApp.projectDetail', {
                        url: '/projectDetail/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/projectDetail/projectDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/projectDetail/ProjectDetailController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })


                    // Definizione della pagina con il dettaglio di una sezione. Il parametro del progetto è opzionale; se presente, sarà inserito nei breadcrumb
                    .state('app.bgacademyApp.sectionDetail', {
                        url: '/sectionDetail/:projectId/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/sectionDetail/sectionDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/sectionDetail/SectionDetailController.js',
                                        'js/routes/sectionDetail/SectionDetailService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_ITEM'
                        }
                    })


                    // Definizione della pagina con la lista dei piani formativi
                    .state('app.bgacademyApp.trainingPlan', {
                        url: '/trainingPlan/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/trainingPlan/trainingPlan.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/trainingPlan/TrainingPlanController.js',
                                        'js/routes/trainingPlan/TrainingPlanService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina per l'integrazione con la Library
                    .state('app.bgacademyApp.library', {
                        url: '/library/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/library/library.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerCard/itemContainerCard.js',
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/library/LibraryController.js',
                                        'js/routes/library/LibraryService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina per il dettaglio di un Learning Plan lato utente
                    .state('app.bgacademyApp.learningPlanDetailUser', {
                        url: '/learningPlanDetailUser/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/learningPlanDetail/learningPlanDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/learningPlanDetail/LearningPlanDetailController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina per il dettaglio di un Learning Plan lato admin
                    .state('app.bgacademyApp.learningPlanDetailAdmin', {
                        url: '/learningPlanDetailAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/learningPlanDetail/learningPlanDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/learningPlanDetail/LearningPlanDetailController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il risultato della ricerca libera
                    .state('app.bgacademyApp.search', {
                        url: '/search/:searchedContent',
                        views: {
                            "content": { templateUrl: 'app/routes/search/search.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/search/SearchController.js',
                                        'js/routes/search/SearchService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di un corso
                    .state('app.bgacademyApp.courseDetail', {
                        url: '/courseDetail/:courseId/:courseDateId/:goToSurvey/:pdfMode',
                        views: {
                            "content": { templateUrl: 'app/routes/courseDetail/courseDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/certification.js',
                                        'js/routes/courseDetail/CourseDetailController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di un corso
                    .state('app.bgacademyApp.courseDetailNoPdf', {
                        url: '/courseDetail/:courseId/:courseDateId/:goToSurvey',
                        views: {
                            "content": { templateUrl: 'app/routes/courseDetail/courseDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/certification.js',
                                        'js/routes/courseDetail/CourseDetailController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di un webinar
                    .state('app.bgacademyApp.webinarDetail', {
                        url: '/webinarDetail/:webinarId',
                        views: {
                            "content": { templateUrl: 'app/routes/webinarDetail/webinarDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/certification.js',
                                        'js/routes/webinarDetail/WebinarDetailController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_WEBINAR',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di un webinar per il docente
                    .state('app.bgacademyApp.webinarDetailTeacher', {
                        url: '/webinarDetailTeacher/:webinarId',
                        views: {
                            "content": { templateUrl: 'app/routes/webinarDetailTeacher/webinarDetailTeacher.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/certification.js',
                                        'js/routes/webinarDetailTeacher/WebinarDetailTeacherController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_USE_WEBINAR',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di un corso (passaggio solo per data)
                    .state('app.bgacademyApp.courseDetailDateOnly', {
                        url: '/courseDetailFromDate/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/courseDetail/courseDetailFromDate.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/courseDetail/CourseDateFromDateController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con le Faq di un corso
                    .state('app.bgacademyApp.faqDetail', {
                        url: '/faqDetail/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/faqDetail/faqDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/faqDetail/FaqDetailController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con i casi di successo di un corso
                    .state('app.bgacademyApp.successStoryDetail', {
                        url: '/successStoryDetail/:courseId/:courseDateId/:videoId',
                        views: {
                            "content": { templateUrl: 'app/routes/successStoryDetail/successStoryDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/successStoryDetail/SuccessStoryDetailController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con le news
                    .state('app.bgacademyApp.news', {
                        url: '/news',
                        views: {
                            "content": { templateUrl: 'app/routes/news/news.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/news/NewsController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con il dettaglio di una news
                    .state('app.bgacademyApp.newsDetail', {
                        url: '/newsDetail/:newsId',
                        views: {
                            "content": { templateUrl: 'app/routes/newsDetail/newsDetail.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newsDetail/NewsDetailController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina per lo svolgimento della verifica di un corso
                    .state('app.bgacademyApp.survey', {
                        url: '/survey/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/survey/survey.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/survey/SurveyController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina per la preview della verifica di un corso
                    .state('app.bgacademyApp.surveyAdmin', {
                        url: '/surveyAdmin/:courseId/:courseDateId/:surveyTemplateId',
                        views: {
                            "content": { templateUrl: 'app/routes/surveyAdmin/surveyAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/surveyAdmin/SurveyAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina post verifica
                    .state('app.bgacademyApp.surveyResult', {
                        url: '/surveyResult/:surveyId/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/surveyResult/surveyResult.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/surveyResult/SurveyResultController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con notifiche
                    .state('app.bgacademyApp.notifications', {
                        url: '/notifications',
                        views: {
                            "content": { templateUrl: 'app/routes/notifications/notifications.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/notifications/NotificationsController.js',
                                        'js/shared/notification/NotificationService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con notifiche
                    .state('app.bgacademyApp.history', {
                        url: '/history',
                        views: {
                            "content": { templateUrl: 'app/routes/history/history.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/historyList.js',
                                        'js/routes/history/HistoryController.js',
                                        'js/routes/history/HistoryService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della della pagina con le impostazioni dell'utente
                    .state('app.bgacademyApp.userSettings', {
                        url: '/userSettings/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/userSettings/userSettings.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/historyList.js',
                                        'js/routes/userSettings/UserSettingsController.js',
                                        'js/routes/trainingPlan/TrainingPlanService.js',
                                        'js/routes/history/HistoryService.js',
                                        'js/routes/userSettings/UserSettingsService.js',
                                        'js/routes/userSettings/UploadController.js',
                                        'js/shared/upload/thumb.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della della pagina con lo switch utente/admin
                    .state('app.bgacademyApp.userMode', {
                        url: '/userMode',
                        views: {
                            "content": { templateUrl: 'app/routes/userMode/userMode.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/userMode/UserModeController.js',
                                        'js/routes/userMode/UserModeService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO'
                        }
                    })

                    // Definizione della pagina impostazioni
                    .state('app.bgacademyApp.settings', {
                        url: '/settings',
                        views: {
                            "content": { templateUrl: 'tpl/settings/settings.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/controllers/settings/SettingsController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina contenente la lista dei template per le mail
                    .state('app.bgacademyApp.templateMailListAdmin', {
                        url: '/templateMailListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/templateMailListAdmin/templateMailListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                function ($ocLazyLoad: any) {
                                    return $ocLazyLoad.load([
                                        'js/routes/templateMailListAdmin/TemplateMailListAdminController.js',
                                        'js/routes/templateMailListAdmin/TemplateMailListAdminService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO'
                        }
                    })

                    // Definizione della pagina contenente la lista dei report
                    .state('app.bgacademyApp.reportListAdmin', {
                        url: '/reportListAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/reportListAdmin/reportListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                function ($ocLazyLoad: any) {
                                    return $ocLazyLoad.load([
                                        'js/routes/reportListAdmin/ReportListAdminController.js',
                                        'js/routes/reportListAdmin/ReportListAdminService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_REPORT_PAGE'
                        }
                    })

                    // Definizione della pagina per la creazione di un nuovo template mail
                    .state('app.bgacademyApp.createTemplateMailAdmin', {
                        url: '/createTemplateMailAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/createTemplateMailAdmin/createTemplateMailAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                function ($ocLazyLoad: any) {
                                    return $ocLazyLoad.load([
                                        'js/routes/createTemplateMailAdmin/CreateTemplateMailAdminController.js',
                                        'js/routes/createTemplateMailAdmin/CreateTemplateMailAdminService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO'
                        }
                    })

                    // Definizione della pagina per l'editing di un template mail
                    .state('app.bgacademyApp.editTemplateMailAdmin', {
                        url: '/editTemplateMailAdmin/:textTemplateId',
                        views: {
                            "content": { templateUrl: 'app/routes/editTemplateMailAdmin/editTemplateMailAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                function ($ocLazyLoad: any) {
                                    return $ocLazyLoad.load([
                                        'js/routes/editTemplateMailAdmin/EditTemplateMailAdminController.js',
                                        'js/routes/editTemplateMailAdmin/EditTemplateMailAdminService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO'
                        }
                    })

                    // Definizione della della pagina di home per l'amministratore
                    .state('app.bgacademyApp.homeAdmin', {
                        url: '/homeAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/homeAdmin/homeAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/homeAdmin/HomeAdminController.js',
                                        'js/routes/homeAdmin/HomeAdminService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della della pagina di home per l'amministratore
                    .state('app.bgacademyApp.newCourseAdmin', {
                        url: '/newCourseAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/newCourseAdmin/newCourseAdmin.html' }
                        },
                        resolve: {
                            /*deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newCourseAdmin/NewCourseAdminController.js',
                                        'js/routes/newCourseAdmin/NewCourseAdminService.js']);
                                }]*/
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newCourseAdmin/NewCourseAdminService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/newCourseAdmin/NewCourseAdminController.js')
                                        });
                                }]

                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della della pagina di home per l'amministratore
                    .state('app.bgacademyApp.newWebinarAdmin', {
                        url: '/newWebinarAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/newWebinarAdmin/newWebinarAdmin.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newCourseAdmin/NewCourseAdminService.js',
                                        'js/routes/createTemplateMailAdmin/CreateTemplateMailAdminService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/newWebinarAdmin/NewWebinarAdminController.js')
                                        });
                                }]

                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della della pagina di modifica di un corso lato amministratore
                    .state('app.bgacademyApp.editCourseAdmin', {
                        url: '/editCourseAdmin/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/editCourseAdmin/editCourseAdmin.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editCourseAdmin/EditCourseAdminService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editCourseAdmin/EditCourseAdminController.js')
                                        });
                                }]

                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della della pagina di modifica di un webinar lato amministratore
                    .state('app.bgacademyApp.editWebinarAdmin', {
                        url: '/editWebinarAdmin/:webinarId',
                        views: {
                            "content": { templateUrl: 'app/routes/editWebinarAdmin/editWebinarAdmin.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newCourseAdmin/NewCourseAdminService.js',
                                        'js/routes/createTemplateMailAdmin/CreateTemplateMailAdminService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/editWebinarAdmin/EditWebinarAdminController.js')
                                        });
                                }]

                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato corsi per l'amministratore
                    .state('app.bgacademyApp.courseListAdmin', {
                        url: '/courseListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/courseListAdmin/courseListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/courseListAdmin/CourseListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato corsi per l'amministratore
                    .state('app.bgacademyApp.webinarListAdmin', {
                        url: '/webinarListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/webinarListAdmin/webinarListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerCard/itemContainerCard.js',
                                        'js/routes/webinarListAdmin/WebinarListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con la lista degli Item
                    .state('app.bgacademyApp.itemListAdmin', {
                        url: '/itemListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemListAdmin/itemListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemListAdmin/ItemListAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la scelta del tipo di oggetto che si vuole creare
                    .state('app.bgacademyApp.chooseItemAdmin', {
                        url: '/chooseItemAdmin/:isContainer',
                        views: {
                            "content": { templateUrl: 'app/routes/chooseItemAdmin/chooseItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/chooseItemAdmin/ChooseItemAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un oggetto
                    .state('app.bgacademyApp.createItemAdmin', {
                        url: '/createItemAdmin',
                        params: {
                            itemType: null // Tipo di oggetto che sto andando a creare
                        },
                        views: {
                            "content": { templateUrl: 'app/routes/createItemAdmin/createItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createItemAdmin/CreateItemAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un oggetto contenitore
                    .state('app.bgacademyApp.createContainerItemAdmin', {
                        url: '/createContainerItemAdmin/:typology',
                        params: {
                            inSession: false
                        },
                        views: {
                            "content": { templateUrl: 'app/routes/createContainerItemAdmin/createContainerItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/createContainerItemAdmin/CreateContainerItemAdminController.js',
                                        'js/routes/createContainerItemAdmin/CreateEditContainerItemAdminManager.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un oggetto contenitore
                    .state('app.bgacademyApp.editContainerItemAdmin', {
                        url: '/editContainerItemAdmin/:itemId',
                        params: {
                            inSession: false
                        },
                        views: {
                            "content": { templateUrl: 'app/routes/createContainerItemAdmin/createContainerItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerList/itemContainerList.js',
                                        'js/routes/createContainerItemAdmin/CreateContainerItemAdminController.js',
                                        'js/routes/createContainerItemAdmin/CreateEditContainerItemAdminManager.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un oggetto contenitore figlio
                    .state('app.bgacademyApp.createSectionContainerItemAdmin', {
                        url: '/createContainerItemAdmin/:typology/section/:sectionIndex',
                        params: {
                            inSession: false
                        },
                        views: {
                            "content": { templateUrl: 'app/routes/createContainerItemAdmin/createContainerItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createContainerItemAdmin/CreateContainerItemAdminController.js',
                                        'js/routes/createContainerItemAdmin/CreateEditContainerItemAdminManager.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la modifica o creazione di un oggetto contenitore figlio durante l'edit di un oggetto playlist/learning plan
                    .state('app.bgacademyApp.editSectionContainerItemAdmin', {
                        url: '/editContainerItemAdmin/:itemId/section/:sectionIndex',
                        params: {
                            inSession: false
                        },
                        views: {
                            "content": { templateUrl: 'app/routes/createContainerItemAdmin/createContainerItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createContainerItemAdmin/CreateContainerItemAdminController.js',
                                        'js/routes/createContainerItemAdmin/CreateEditContainerItemAdminManager.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la selezione degli oggetti atomici di una sezione o di una playlist
                    .state('app.bgacademyApp.selectItemsContainer', {
                        params: { backAction: '', backActionStateParams: null },
                        url: '/selectItemsContainer/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/createContainerItemAdmin/selectItems/selectItems.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createContainerItemAdmin/selectItems/SelectItemsController.js',
                                        'js/routes/createContainerItemAdmin/CreateEditContainerItemAdminManager.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la modifica o creazione di un oggetto contenitore figlio durante l'edit di un oggetto playlist/learning plan
                    .state('app.bgacademyApp.itemInvitedListAdmin', {
                        url: '/itemInvitedListAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemInvitedListAdmin/itemInvitedListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemInvitedListAdmin/ItemInvitedListAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la modifica di un oggetto
                    .state('app.bgacademyApp.editItemAdmin', {
                        url: '/editItemAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/editItemAdmin/editItemAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editItemAdmin/EditItemAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato corsi per l'amministratore
                    .state('app.bgacademyApp.cardUser', {
                        url: '/cardUser/:userId/:sourcePage/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/cardUser/cardUser.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/cardUser/CardUserController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di dettaglio corso per l'amministratore
                    .state('app.bgacademyApp.courseDetailAdmin', {
                        url: '/courseDetailAdmin/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/courseDetailAdmin/courseDetailAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/certification.js',
                                        'js/routes/courseDetailAdmin/CourseDetailAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di dettaglio webinar per l'amministratore
                    .state('app.bgacademyApp.webinarDetailAdmin', {
                        url: '/webinarDetailAdmin/:webinarId',
                        views: {
                            "content": { templateUrl: 'app/routes/webinarDetailAdmin/webinarDetailAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/certification.js',
                                        'js/routes/webinarDetailAdmin/WebinarDetailAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    .state('app.bgacademyApp.faqDetailAdmin', {
                        url: '/faqDetailAdmin/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/faqDetailAdmin/faqDetailAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/faqDetailAdmin/FaqDetailAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con i casi di successo di un corso
                    .state('app.bgacademyApp.successStoryDetailAdmin', {
                        url: '/successStoryDetailAdmin/:courseId/:courseDateId/:videoId',
                        views: {
                            "content": { templateUrl: 'app/routes/successStoryDetailAdmin/successStoryDetailAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/successStoryDetailAdmin/SuccessStoryDetailAdminController.js',
                                        'js/shared/global/ItemService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con il dettaglio di un oggetto singolo, quindi NON contenuto in un contenitore (potrei arrivarci tramite la ricerca libera)
                    .state('app.bgacademyApp.itemDetailUser', {
                        url: '/itemDetailUser/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della pagina con la preview di un oggetto lato admin
                    .state('app.bgacademyApp.itemDetailAdmin', {
                        url: '/itemDetailAdmin/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/itemDetail/itemDetail.html' }
                        },
                        resolve: {
                            loadMyService: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/itemDetail/SearchItemsService.js',
                                        'js/shared/global/ItemService.js'])
                                        .then(() => {
                                            return $ocLazyLoad.load('js/routes/itemDetail/ItemDetailController.js')
                                        });
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato progetti per l'amministratore
                    .state('app.bgacademyApp.projectListAdmin', {
                        url: '/projectListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/projectListAdmin/projectListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/projectListAdmin/ProjectListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con la lista di tutte le comunicazioni di una edizione
                    .state('app.bgacademyApp.courseDateCommunications', {
                        url: '/communications/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/courseDateCommunications/courseDateCommunications.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/courseDateCommunications/CourseDateCommunicationsController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina creazione progetti per l'amministratore
                    .state('app.bgacademyApp.createProjectAdmin', {
                        url: '/createProjectAdmin',
                        params: { selectedCourses: null },
                        views: {
                            "content": { templateUrl: 'app/routes/createProjectAdmin/createProjectAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createProjectAdmin/CreateProjectAdminController.js',
                                        'js/shared/global/CourseService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina creazione progetti per l'amministratore
                    .state('app.bgacademyApp.editProjectAdmin', {
                        url: '/editProjectAdmin/:trainingId',
                        views: {
                            "content": { templateUrl: 'app/routes/editProjectAdmin/editProjectAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editProjectAdmin/EditProjectAdminController.js',
                                        'js/shared/global/CourseService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato progetti per l'amministratore
                    .state('app.bgacademyApp.subscriptionListAdmin', {
                        url: '/subscriptionListAdmin/:courseId/:courseDateId',
                        views: {
                            "content": { templateUrl: 'app/routes/subscriptionListAdmin/subscriptionListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'assets/pdf-export-withouth-canvas/subscriptionList.js',
                                        'js/routes/subscriptionListAdmin/SubscriptionListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato gruppi per l'amministratore
                    .state('app.bgacademyApp.groupListAdmin', {
                        url: '/groupListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/groupListAdmin/groupListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/groupListAdmin/GroupListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato utenti per l'amministratore
                    .state('app.bgacademyApp.userListAdmin', {
                        url: '/userListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/userListAdmin/userListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/userListAdmin/UserListAdminController.js',
                                        'js/routes/userListAdmin/UserListAdminService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di gestione attestati di un altro intermediario
                    .state('app.bgacademyApp.certificateListAdmin', {
                        url: '/certificateListAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/certificateListAdmin/certificateListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/certificateListAdmin/certificateListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la creazione di un gruppo
                    .state('app.bgacademyApp.createGroupAdmin', {
                        url: '/createGroupAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/createGroupAdmin/createGroupAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createGroupAdmin/CreateGroupAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina per la modifica di un gruppo
                    .state('app.bgacademyApp.editGroupAdmin', {
                        url: '/editGroupAdmin/:groupId',
                        views: {
                            "content": { templateUrl: 'app/routes/editGroupAdmin/editGroupAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editGroupAdmin/EditGroupAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di listato news per l'amministratore
                    .state('app.bgacademyApp.newsListAdmin', {
                        url: '/newsListAdmin/:searchId',
                        views: {
                            "content": { templateUrl: 'app/routes/newsListAdmin/newsListAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newsListAdmin/NewsListAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di creazione news per l'amministratore
                    .state('app.bgacademyApp.createNewsAdmin', {
                        url: '/createNewsAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/createNewsAdmin/createNewsAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/createNewsAdmin/CreateNewsAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di modifica news per l'amministratore
                    .state('app.bgacademyApp.editNewsAdmin', {
                        url: '/editNewsAdmin/:announcementId',
                        views: {
                            "content": { templateUrl: 'app/routes/editNewsAdmin/editNewsAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/editNewsAdmin/EditNewsAdminController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina di anteprima di una news
                    .state('app.bgacademyApp.newsPreview', {
                        url: '/newsPreview/:announcementId',
                        views: {
                            "content": { templateUrl: 'app/routes/newsPreview/newsPreview.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/newsPreview/NewsPreviewController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })


                    // Definizione dell'entrypoint monitoringAdmin
                    .state('app.bgacademyApp.monitoringAdmin', {
                        url: '/monitoringAdmin',
                        abstract: true,
                        params: { skipInit: false },
                        redirectTo: 'app.bgacademyApp.monitoringAdmin.hourTraining',
                        views: {
                            "content": { templateUrl: 'app/routes/monitoringAdmin/monitoringAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/MonitoringAdminController.js',
                                        'js/routes/monitoringAdmin/MonitoringService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            forceAdminMode: true,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con il numero ore di formazione
                    .state('app.bgacademyApp.monitoringAdmin.hourTraining', {
                        url: '/hourTraining',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/hourTraining/hourTraining.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/hourTraining/HourTrainingController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con l'indicatore contenente gli utenti
                    .state('app.bgacademyApp.monitoringAdmin.users', {
                        url: '/users',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/users/users.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/users/UsersController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })


                    // Definizione della pagina con il tempo medio deglio oggetti consultati
                    .state('app.bgacademyApp.monitoringAdmin.consumedItemsAvarageTime', {
                        url: '/consumedItemsAvarageTime',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/consumedItemsAvarageTime/consumedItemsAvarageTime.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/consumedItemsAvarageTime/ConsumedItemsAvarageTimeController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con il tempo medio deglio oggetti consultati
                    .state('app.bgacademyApp.monitoringAdmin.mostDetectedDevices', {
                        url: '/mostDetectedDevices',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/mostDetectedDevices/mostDetectedDevices.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/mostDetectedDevices/MostDetectedDevicesController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con per i crediti EFPA
                    .state('app.bgacademyApp.monitoringAdmin.courseCredits', {
                        url: '/courseCredits',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/courseCredits/courseCredits.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/courseCredits/CourseCreditsController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con per i crediti EFPA
                    .state('app.bgacademyApp.monitoringAdmin.ivassCredits', {
                        url: '/ivassCredits',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/courseCredits/ivassCredits.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/courseCredits/IvassCreditsController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con per i crediti EFPA
                    .state('app.bgacademyApp.monitoringAdmin.courseUsersInvited', {
                        url: '/courseUsersInvited',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/courseUsers/courseUsersInvited.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/courseUsers/CourseUsersInvitedController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con per i crediti EFPA
                    .state('app.bgacademyApp.monitoringAdmin.courseUsersPresent', {
                        url: '/courseUsersPresent',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/courseUsers/courseUsersPresent.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/courseUsers/CourseUsersPresentController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina con per gli utenti più attivi
                    .state('app.bgacademyApp.monitoringAdmin.topActiveUsers', {
                        url: '/topActiveUsers',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/topActiveUsers/topActiveUsers.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/topActiveUsers/TopActiveUsersController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina Tasso di partecipazione corsi
                    .state('app.bgacademyApp.monitoringAdmin.coursesHigherParticipationRate', {
                        url: '/coursesHigherParticipationRate',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/courseUsers/coursesHigherParticipationRate.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/courseUsers/CoursesHigherParticipationRateController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina Tasso di partecipazione medio ai corsi
                    .state('app.bgacademyApp.monitoringAdmin.courseMonitor', {
                        url: '/courseMonitor',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/courseMonitor/courseMonitor.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/courseMonitor/CourseMonitorController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina Tasso di partecipazione medio ai corsi
                    .state('app.bgacademyApp.monitoringAdmin.sectionMonitor', {
                        url: '/sectionMonitor',
                        views: {
                            "dashboardContent": { templateUrl: 'app/routes/monitoringAdmin/sectionMonitor/sectionMonitor.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/monitoringAdmin/sectionMonitor/SectionMonitorController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_DASHBOARD',
                            dashboardScope: null,
                            pageName: "dashboard"
                        }
                    })

                    // Definizione della pagina di gestione template per l'amministratore
                    .state('app.bgacademyApp.templatingAdmin', {
                        url: '/templatingAdmin',
                        views: {
                            "content": { templateUrl: 'app/routes/templatingAdmin/templatingAdmin.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/routes/templatingAdmin/TemplatingAdminController.js',
                                        'js/routes/templatingAdmin/TemplateService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_EVERY_USER_INFO',
                            forceAdminMode: true
                        }
                    })

                    // Definizione della pagina con la lista dei webinar
                    .state('app.bgacademyApp.webinarList', {
                        url: '/webinarList',
                        views: {
                            "content": { templateUrl: 'app/routes/webinarList/webinarList.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/itemContainerCard/itemContainerCard.js',
                                        'js/routes/webinarList/WebinarListController.js',
                                        'js/routes/webinarList/WebinarListService.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO',
                            forceAdminMode: false
                        }
                    })

                    // Definizione della della pagina della webinar room
                    .state('app.bgacademyApp.webinarRoom', {
                        url: '/webinarRoom/:webinarId',
                        views: {
                            "content": { templateUrl: 'app/routes/webinarRoom/webinarRoom.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/global/ItemService.js',
                                        'js/routes/webinarRoom/ChatService.js',
                                        'js/routes/webinarRoom/WebinarRoomService.js',
                                        'js/shared/bus/BusServiceUtils.js',
                                        'js/shared/kurentoUtils/kurentoUtils.js',
                                        'js/shared/bus/bus.js',
                                        'js/routes/webinarRoom/WebinarRoomController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO'
                        }
                    })

                    // Definizione della pagina della lista utenti di un wwebinar
                    .state('app.bgacademyApp.userListWebinar', {
                        url: '/userListWebinar/:itemId',
                        views: {
                            "content": { templateUrl: 'app/routes/userListWebinar/userListWebinar.html' }
                        },
                        resolve: {
                            deps: ['$ocLazyLoad',
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load([
                                        'js/shared/global/ItemService.js',
                                        // 'js/routes/webinarRoom/ChatService.js',
                                        // 'js/routes/webinarRoom/WebinarLiveService.js',
                                        // 'js/routes/webinarRoom/WebinarRoomService.js',
                                        'js/shared/bus/bus.js',
                                        'js/routes/userListWebinar/userListWebinarController.js']);
                                }]
                        },
                        data: {
                            requiredAuthId: 'BGACADEMY_VIEW_SELF_INFO'
                        }
                    })

                    .state("app.access.error", {
                        url: "/error/:errorCode",
                        templateUrl: "tpl/page_error.html",
                        resolve: {
                            deps: ["$ocLazyLoad",
                                ($ocLazyLoad: any) => {
                                    return $ocLazyLoad.load(["js/controllers/ErrorCtrl.js"]);
                                }]
                        }
                    })
                    .state("app.access.403", {
                        url: "/403",
                        templateUrl: "app/routes/errors/page_403.html"
                    })
                    .state("app.access.404", {
                        url: "/404",
                        templateUrl: "app/routes/errors/page_404.html"
                    })
            }])

    // Definisco l'interceptor per iniettare nell'header il token e che identifica la risposta "necessario login"
    .factory('tokenInjectInterceptor', ($q: angular.IQService, $location: ng.ILocationService, $sessionStorage: any, GlobalApplicationData: any, BgacademyApplicationData: any, $injector: ng.auto.IInjectorService) => {
        return {
            request: (config: any) => {
                config.headers = config.headers || {};
                if (GlobalApplicationData.identityToken) {
                    config.headers.Authorization = 'Bearer ' + GlobalApplicationData.identityToken;
                }
                else {
                    // Non ho i dati, quindi vado alla pagina che cerca o ricrea il token di autenticazione
                    // Ammesso che non stia già andando lì... (o stia caricando il main template che fa parte del "lì")
                    if (config.url && (config.url.indexOf("app/routes/login/login.html") === -1 && config.url.indexOf("app/routes/login/adminLogin.html") === -1 && config.url.indexOf("app/routes/csessologin/csessologin.html") === -1 && config.url.indexOf("app/shared/global/commonServices.html") === -1)) {
                        // Mi salvo dove era richiesto andare (se non ne ho già uno)
                        if (!GlobalApplicationData.orginalRequestedURL && ($location.path().indexOf("/login") === -1 && $location.path().indexOf("/adminLogin") === -1 && $location.path().indexOf("/csessologin") === -1)) {
                            GlobalApplicationData.orginalRequestedURL = $location.path();
                        }
                        let $state: any = $injector.get("$state");
                        if (!$state.current || ($state.current.data && $state.current.data.requiredAuthId)) {
                            $state.go("app.login");
                        }
                    }
                }

                return config;
            },

            response: (response: any) => {
                // Le risposte dei servizi possono contenere una SenecaResponse che ha un codice di errore e la risposta
                // Nel caso il codice sia uno specifico (equivalente del 401 del responseError), allora butto fuori l'utente
                if (response && response.data && response.data.error &&
                    (response.data.error === BgacademyApplicationData.constants.LOGGED_USER_NOT_FOUND || response.data.error === BgacademyApplicationData.constants.USER_WITHOUT_AUTHS)) {
                    // La sessione utente è sicuramente scaduta, quindi cancello anche il token
                    $sessionStorage.identityToken = null;
                    GlobalApplicationData.identityToken = null;
                    GlobalApplicationData.jwtPayload = null;
                    GlobalApplicationData.showSessionExpiredAlert = true;
                    // Vado alla pagina che cerca o ricrea il token di autenticazione
                    //$location.path('#/app/authenticating');
                    var $state: any = $injector.get("$state");
                    $state.go("app.login");
                }
                return response;
            },

            responseError: (rejection: any) => {
                if (rejection.status == 401) {
                    // La sessione utente è sicuramente scaduta, quindi cancello anche il token
                    $sessionStorage.identityToken = null;
                    GlobalApplicationData.identityToken = null;
                    GlobalApplicationData.jwtPayload = null;
                    GlobalApplicationData.showSessionExpiredAlert = true;
                    // Vado alla pagina che cerca o ricrea il token di autenticazione
                    //$location.path('#/app/authenticating');
                    var $state: any = $injector.get("$state");
                    $state.go("app.login");
                }
                return $q.reject(rejection);
            }
        };
    })

    .config(['$httpProvider', ($httpProvider: angular.IHttpProvider) => {
        $httpProvider.interceptors.push('tokenInjectInterceptor');
    }])

    // Definisco il listener per il cambio di state (pagina) che controlla i diritti degli utenti
    .run(($rootScope: ng.IScope, $state: angular.ui.IStateService, UserService: any, GlobalApplicationData: any, $sessionStorage: any, rfc4122: any, $cookies: any, $log: ng.ILogService) => {

        // todo: cookie key in commonclasses

        // assegno un deviceId se già non ne posseggo uno
        // let deviceId: string = $cookies.get("deviceId");

        // if (angular.isUndefined(deviceId)) {
        //     deviceId = rfc4122.v4();
        //     $cookies.put("deviceId", deviceId);
        // }

        // GlobalApplicationData.deviceId = deviceId;

        GlobalApplicationData.deviceId = rfc4122.v4();


        $log.debug("GlobalApplicationData.deviceId", GlobalApplicationData.deviceId);

        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            // Se ho dei parametri impostati per i diritti di visibilità
            if (toState.data && toState.data.requiredAuthId) {
                // Verifico se ho già fatto un login o sto andando lì
                if (GlobalApplicationData.identityToken || toState.name === "app.login") {
                    // Verifico se sono abilitato a vederli
                    if (!UserService.isUserAuthorized(toState.data.requiredAuthId)) {
                        event.preventDefault();
                        //return false;
                        $state.go('app.access.403');
                    }
                }
                else {
                    // Non ho ancora fatto login, quindi mando l'utente alla pagina dedicata
                    event.preventDefault();
                    $state.go("app.login");
                }
            }

            // Tengo traccia del fatto che sono nella sezione di amministrazione
            if (toState.data && toState.data.forceAdminMode === true) {
                $sessionStorage.adminMode = true;
                // Se arrivo dalla pagina delle notifiche, devo alzare un evento che venga intercettato dal BgacademyApplicationController, affinché aggiorni l'header (da utente ad amministratore)
                if (fromState.name == 'app.bgacademyApp.notifications') {
                    $rootScope.$emit("switchedIntoAdminMode");
                }
            } else if (toState.data && toState.data.forceAdminMode === false) {
                $sessionStorage.adminMode = false;
            }
        })
    })